<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap bgw archives">
					<div class="nav">
						<div @click="navTab = 0" :class="{navActive: navTab == 0}">我的档案</div>
						<div @click="navTab = 1,type =0" :class="{navActive: navTab == 1}">添加档案</div>
					</div>
					<div v-if="navTab == 0">
						<div class="box1">
							<div class="titleList">
								<div>姓名</div>
								<div>年龄</div>
								<div>性别</div>
								<div>身高</div>
								<div>操作</div>
							</div>
							<div class="userList" v-for="(v,i) in list" :key="i" style="border-bottom: 2px solid #E5E5E5;">
								<div>{{v.realname}}</div>
								<div>{{ getTime(v.birthday)-1}}</div>
								<div v-if="v.sex == 0">男</div>
								<div v-else-if="v.sex == 1">女</div>
								<div v-else>保密</div>
								<div>{{v.height}}cm</div>
								<div>
									<span @click="editData(v)">编辑</span>
									<span @click="removeData(v)">删除</span>
								</div>
							</div>
						</div>
					</div>
					<div @click="editRecord = 0" v-show="editRecord == 1" class="editRecord">
						<div class="editRecord_box">
							<div @click.stop class="ad_title">
								编辑档案信息
								<img @click="closeRecord" class="none" src="@/assets/image/Interrogation_Popup_Close@2x.png" alt />
							</div>
							<div @click.stop class="ar_box">
								<div class="ar_txt">
									<div class="ar_txts">
										姓
										<i></i>
										<i></i>
										<i></i>
										<i></i> 名
									</div>
									<input v-model="currentArchives.realname" type="text" maxlength="12" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">
										性
										<i></i>
										<i></i>
										<i></i>
										<i></i> 别
									</div>
									<div class="as">
										<el-radio v-model="sex" label="0">男</el-radio>
										<el-radio v-model="sex" label="1">女</el-radio>
									</div>
								</div>
								<div class="ar_txt w60">
									<div class="ar_txts">
										生
										<i></i>
										<i></i>
										<i></i>日
									</div>
									<el-date-picker v-model="userbirthday" type="date" placeholder=" 选择日期"></el-date-picker>
								</div>
								<div class="ar_txt w60">
									<div class="ar_txts">
										身
										<i></i>
										<i></i>
										<i></i> 高
									</div>
									<input v-model="currentArchives.height" type="text" maxlength="3" />C M
								</div>
								<div class="ar_txt w60">
									<div class="ar_txts">
										体
										<i></i>
										<i></i>
										<i></i> 重
									</div>
									<input v-model="currentArchives.weight" type="text" maxlength="3" />K G
								</div>
								<div class="ar_txt">
									<div class="ar_txts">电话</div>
									<input v-model="currentArchives.mobile" type="text" maxlength="11" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">现住址</div>
									<input v-model="currentArchives.address" type="text" maxlength="11" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">联系人</div>
									<input v-model="currentArchives.linkman" type="text" maxlength="11" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">与联系人关系</div>
									<el-select v-model="currentArchives.relationship" placeholder="请选择" @change="getname(1)">
										<el-option v-for="item in relatlist" :key="item.code" :label="item.name" :value="item.code">
										</el-option>
									</el-select>
								</div>
								<div class="ar_txt">
									<div class="ar_txts">联系人住址</div>
									<input v-model="currentArchives.linkmanAddr" type="text" maxlength="11" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">联系人电话</div>
									<input v-model="currentArchives.linkmanTele" type="text" maxlength="11" />
								</div>
								<div class="ar_txt iscard">
									<div class="ar_txts">身份证号</div>
									<input v-model="currentArchives.idcard" type="text" maxlength="18" />
								</div>
								<div class="ar_txt">
									<div class="ar_txts">
										过
										<i></i>敏
										<i></i>史
									</div>
									<textarea v-model="currentArchives.allergy"></textarea>
								</div>
								<div class="ar_txt">
									<div class="ar_txts">
										遗
										<i></i>传
										<i></i>史
									</div>
									<textarea v-model="currentArchives.genetic_disease"></textarea>
								</div>
								<div class="ar_txt">
									<div class="ar_txts">付费方式</div>
									<el-select v-model="currentArchives.medicalPayment" placeholder="请选择">
										<el-option v-for="item in medpay" :key="item.code" :label="item.name" :value="item.code">
										</el-option>
									</el-select>
								</div>
								<div class="ar_txt">
									<div class="ar_txts">
										上传检查报告
									</div>
									<div class="imgbox">
										<el-upload :action="imageUrl" list-type="picture-card" :on-success="ListImgSuccess" :before-upload="beforeAvatarUpload"
										 :file-list="fileList" :on-remove="ListImgRemove">
											<i class="el-icon-plus"></i>
										</el-upload>
									</div>
								</div>
								<p @click="savaArchives()">保存</p>
							</div>
						</div>
					</div>
					<div v-if="navTab == 1">
						<div class="settxt">
							<div class="addRecord_box">
								<div @click.stop class="ar_box">
									<div class="ar_txt">
										<div class="ar_txts">
											姓
											<i></i>
											<i></i>
											<i></i>
											<i></i> 名
										</div>
										<input v-model="name" type="text" maxlength="12" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">
											性
											<i></i>
											<i></i>
											<i></i>
											<i></i> 别
										</div>
										<div class="as">
											<el-radio v-model="sex" label="0">男</el-radio>
											<el-radio v-model="sex" label="1">女</el-radio>
										</div>
									</div>
									<div class="ar_txt w60">
										<div class="ar_txts" style="line-height:40px">
											年
											<i></i>
											<i></i>
											<i></i>龄
										</div>
										<el-date-picker v-model="age" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
									</div>
									<div class="ar_txt w60">
										<div class="ar_txts">
											身
											<i></i>
											<i></i>
											<i></i> 高
										</div>
										<input v-model="h" type="text" maxlength="3" />C M
									</div>
									<div class="ar_txt w60">
										<div class="ar_txts">
											体
											<i></i>
											<i></i>
											<i></i> 重
										</div>
										<input v-model="kg" type="text" maxlength="3" />K G
									</div>
									<div class="ar_txt">
										<div class="ar_txts">电话</div>
										<input v-model="mobile" type="text" maxlength="11" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">现住址</div>
										<input v-model="address" type="text" maxlength="11" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">联系人</div>
										<input v-model="linkman" type="text" maxlength="11" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">与联系人关系</div>
										<el-select v-model="relationship" placeholder="请选择" @change="getname(2)">
											<el-option v-for="item in relatlist" :key="item.code" :label="item.name" :value="item.code">
											</el-option>
										</el-select>
									</div>
									<div class="ar_txt">
										<div class="ar_txts">联系人住址</div>
										<input v-model="linkmanAddr" type="text" maxlength="11" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">联系人电话</div>
										<input v-model="linkmanTele" type="text" maxlength="11" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">身份证号</div>
										<input v-model="idcard" type="text" maxlength="18" />
									</div>
									<div class="ar_txt">
										<div class="ar_txts">
											过
											<i></i>敏
											<i></i>史
										</div>
										<textarea v-model="guominshi"></textarea>
									</div>
									<div class="ar_txt">
										<div class="ar_txts">
											遗
											<i></i>传
											<i></i>史
										</div>
										<textarea v-model="yichuanshi"></textarea>
									</div>
									<div class="ar_txt">
										<div class="ar_txts">付费方式</div>
										<el-select v-model="medpayType" placeholder="请选择">
											<el-option v-for="item in medpay" :key="item.code" :label="item.name" :value="item.code">
											</el-option>
										</el-select>
									</div>
									<div class="ar_txt" sty>
										<div class="ar_txts">
											上传检查报告
										</div>
										<div v-if=" imgs!=='' " class="imgArr">
											<div @click="removeImgaa(v)" @mouseover="dele_icon =i" @mouseout="dele_icon = -1" v-for="(v,i) in imgs" :key="i">
												<div :class="{rgb6:dele_icon==i}" v-show="dele_icon == i">
													<i class="el-icon-delete"></i>
												</div>

												<img :src="v" alt />
											</div>
										</div>
										<div class="imgbox">
											<el-upload :action="imageUrl" list-type="picture-card" :on-success="ListImgSuccess" :before-upload="beforeAvatarUpload"
											 :on-remove="ListImgRemove">
												<i class="el-icon-plus"></i>
											</el-upload>
										</div>
									</div>
									<div class="ar_txt">
										<div class="ar_txts"></div>
										<button @click="checking()">保存档案</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	import {
		add_dangan
	} from "@/request/user";
	import {
		dangan_getList
	} from "@/request/user";
	import {
		del_dangan
	} from "@/request/user";
	import {
		getBedInfor
	} from "@/request/personnel";
	import moment from "moment";
	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				a: 0,
				linkmanTele: "", //联系人电话
				linkmanAddr: "", //联系人地址
				relationship: "", //联系人关系
				linkman: "", //联系人
				address: "", //地址
				relationship_name: "",
				condition: "", //患病程度
				conditionlist: [{
						key: "1",
						value: "危重"
					},
					{
						key: "2",
						value: "急诊"
					},
					{
						key: "3",
						value: "一般"
					},
					{
						key: "9",
						value: "其他"
					},
				],
				userList: [], // 个人信息
				dele_icon: -1, //编辑 档案
				navTab: 0, // 列表 还是 新增
				name: "", // 姓名
				sex: "0", //0男   1 女
				age: "",
				h: "",
				kg: "",
				mobile: "",
				record: "",
				idcard: "",
				guominshi: "",
				yichuanshi: "",
				bingqing: "",
				imgArr: "", //资质证明 图的 list
				imgs: [], //编辑 档案 的图片 列表
				list: [], // 档案 列表
				type: 0,
				ids: "",
				editRecord: 0,
				currentArchives: {}, // 当前档案对象
				radio: 0,
				value: "",
				medpay: [],
				medpayType: "全自费",
				relatlist: [],
				rela: "",
				fileList: [], //问诊的图片
				userbirthday:"",
			};
		},
		created() {
			getBedInfor({
				table: "DOMESTICRELATION"
			}).then(res => {
				this.relatlist = res.data;
			});
			getBedInfor({
				table: "HOSPITALIZATIONSTATE "
			}).then(res => {
				this.conditionlist = res.data;
			});
			getBedInfor({
				table: "MEDPAYTYPE"
			}).then(res => {
				this.medpay = res.data;
			})
			let time = Date.now() / 1000 - this.getTime("2010-13-1");
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}

			this.init();
			// this.state=this.$route.query.state
			this.start();
		},
		methods: {
			getLocalTime(nS) {     
				console.log(nS);
			  //shijianchuo是整数，否则要parseInt转换
			  var time = new Date(nS);
			  var y = time.getFullYear();
			  var m = time.getMonth()+1;
			  var d = time.getDate();
			  var h = time.getHours();
			  var mm = time.getMinutes();
			  var s = time.getSeconds();
			  return y+'-'+(m>=10?m:"0"+m)+'-'+(d>=10?d:"0"+d);
			  
			},
			getname(val) {
				let obj = {};
				if (val == 1) {
					obj = this.relatlist.find((item) => {
						return item.code == this.currentArchives.relationship;
					});
					this.currentArchives.relationship_name = obj.name;
				} else {
					obj = this.relatlist.find((item) => {
						return item.code == this.relationship;
					});
					this.relationship_name = obj.name;
				}

			},
			getTime(datetime) {
				// 通过 yyyy-mm-dd 计算具体年数
				var tmp_datetime = datetime.replace(/:/g, "-");
				tmp_datetime = tmp_datetime.replace(/ /g, "-");
				var arr = tmp_datetime.split("-");
				var now = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2]));
				return Math.ceil(
					(Date.now() / 1000 - parseInt(now.getTime() / 1000)) /
					60 /
					60 /
					24 /
					365
				);
			},
			closeRecord() {
				this.editRecord = 0;
			},
			removeImgaa(e) {
				var index = this.imgs.indexOf(e);
				if (index > -1) {
					this.imgs.splice(index, 1);
				}
			},
			txt(e) {
				this.name = e.realname;
				e.sex = String(e.sex);
				this.sex = e.sex;
				this.age = e.birthday;
				this.h = e.height;
				this.kg = e.weight;
				this.mobile = e.mobile;
				this.guominshi = e.allergy;
				this.yichuanshi = e.genetic_disease;
				this.bingqing = e.disease_desc;
				this.imgs = e.report_arr;
				this.ids = e.id;
				this.type = 1;
				this.navTab = 1;
			},
			removeData(e) {
				del_dangan({
					token: this.$tokens,
					id: e.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "删除成功",
							type: "success"
						});
						this.start();
					}
				});
			},
			start() {
				dangan_getList({
					token: this.$tokens
				}).then(res => {
					this.list = res.data.data;
					for (let i = 0; i < this.list.length; i++) {
						// let time = this.list[i].birthday;
						// time = time * 1000;
						// var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
						// var Y = date.getFullYear() + "-";
						// var M =
						// 	(date.getMonth() + 1 < 10 ?
						// 		"0" + (date.getMonth() + 1) :
						// 		date.getMonth() + 1) + "-";

						// var D =
						// 	(date.getDate() + 1 < 10 ?
						// 		"0" + (date.getDate() + 1) :
						// 		date.getDate() + 1) + "-";
						// var h = date.getHours() + ":";
						// var m = date.getMinutes() + ":";
						// var s = date.getSeconds();
						// let times = Y + M + D + h + m + s;
						// times = times.substr(0, 10);
						this.list[i].birthday = this.getLocalTime(this.list[i].birthday*1000);
					}
				});
			},
			editData(v) {
				console.log(v);
				this.fileList = [];
				// 编辑档案
				for (let item of v.report_arr) {
					this.fileList.push({
						name: item,
						url: item,
					});
				}
				this.currentArchives = v;
				this.editRecord = 1;
				this.sex = v.sex.toString();
				
				this.userbirthday=this.getLocalTime(v.birthday);
				this.imgArr = this.currentArchives.report_urls;
			},
			savaArchives() {
				this.currentArchives.report_urls = this.imgArr;
				// 保存修改的档案
				add_dangan({
					token: this.$token,
					...this.currentArchives,
					birthday:this.getLocalTime(this.userbirthday) ,
					sex: this.sex
				}).then(res => {
					if (res.code == 1) {
						this.start();
					}
					this.editRecord = 0;
				});
			},
			checking(type) {
				if (this.name == "") {
					this.$message.error("姓名不能为空");
				} else if (this.age == "") {
					this.$message.error("年龄不能为空");
				} else if (this.h == "") {
					this.$message.error("身高不能为空");
				} else if (this.kg == "") {
					this.$message.error("体重不能为空");
				} else if (this.mobile == "") {
					this.$message.error("联系电话不能为空");
				} else if (this.idcard == "") {
					this.$message.error("身份证号不能为空");
				} else if (this.guominshi == "") {
					this.$message.error("过敏史不能为空");
				} else if (this.yichuanshi == "") {
					this.$message.error("遗传史不能为空");
				} else {
					if (this.type == 0) {
						this.send();
					} else {
						this.sends();
					}
				
				}
			},
			clearvalue() {
				this.name = "";
				this.sex = 0;
				this.age == "";
				this.h = "";
				this.kg = "";
				this.mobile = "";
				this.idcard = "";
				this.guominshi = "";
				this.yichuanshi = "";
				this.address="";
				this.linkman="";
				this.linkmanAddr="";
				this.linkmanTele="";
			},
			send() {
				add_dangan({
					token: this.$tokens,
					realname: this.name,
					mobile: this.mobile,
					sex: this.sex,
					birthday: this.age,
					weight: this.kg,
					height: this.h,
					allergy: this.guominshi,
					idcard: this.idcard,
					genetic_disease: this.yichuanshi,
					disease_desc: this.bingqing,
					report_urls: this.imgArr,
					linkmanTele: this.linkmanTele,
					linkmanAddr: this.linkmanAddr,
					relationship: this.relationship,
					linkman: this.linkman,
					address: this.address,
					relationship_name: this.relationship_name

				}).then(res => {
					if (res.code == 1) {
						this.imgArr = [];
						this.start();
						this.navTab = 0;
						this.$message({
							message: "保存成功",
							type: "success"
						});
							this.clearvalue();
					} else if (res.code == 0) {
						this.$message.error(res.msg);
					}
				});
			},
			sends() {
				let imgas = this.imgArr;
				add_dangan({
					token: this.$tokens,
					id: this.ids,
					realname: this.name,
					mobile: this.mobile,
					sex: this.sex,
					birthday: this.age,
					weight: this.kg,
					height: this.h,
					allergy: this.guominshi,
					genetic_disease: this.yichuanshi,
					disease_desc: this.bingqing,
					report_urls: this.imgArr,
					medicalPayment: this.medpayType,
				}).then(res => {
					if (res.code == 1) {
						this.imgArr = "";
						this.start();
						this.navTab = 0;
						this.$message({
							message: "保存成功",
							type: "success"
						});
							this.clearvalue();
					} else if (res.code == 0) {
						this.$message.error(res.msg);
					}
				});
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			//   资质证明 图的 list
			ListImgSuccess(response, file, fileList) {
				let imgurl = response.data.file.url;
				file.url = imgurl;
				console.log(imgurl);
				this.imgArr = imgurl;
			},
			//   资质证明 删除
			ListImgRemove(file, fileList) {
				this.imgArr = "";
				this.a = 0;

			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	.imgbox {
		width: 148px;
		height: 148px;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		>div {
			width: 100%;
			height: 100%;
		}

		.del {
			display: inline-block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 18px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			text-align: center;
			line-height: 148px;
		}
	}

	.imgArr {
		display: inherit;

		>div {
			margin-right: 20px;
			display: inherit;
			position: relative;
			width: 148px;
			height: 148px;
		}

		i {
			color: white;
			width: 30px;
			height: 30px;
			font-size: 20px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.rgb6 {
			position: absolute;
			top: 0;
			left: 0;
			width: 148px;
			height: 148px;
			background: rgba(0, 0, 0, 0.4);
			z-index: 2;
		}

		img {
			display: inline-block;
			border-radius: 6px;
			width: 148px;
			height: 148px;
		}
	}

	.addRecord_box {
		padding-left: 40px;
		padding-bottom: 80px;

		.ad_title {
			line-height: 40px;
			height: 40px;
			background: rgba(247, 247, 247, 1);
			font-size: 14px;
			padding-left: 12px;
			padding-right: 12px;
			position: relative;

			.none {
				position: absolute;
				right: 24px;
				top: 14px;
				width: 14px;
				height: 14px;
			}
		}

		.ar_box {
			padding-left: 30px;
			font-size: 14px;
			padding-top: 20px;

			i {
				margin-right: 8px;
			}

			.ar_txt {
				font-size: 14px;
				margin-bottom: 24px;
				line-height: 24px;
				display: flex;
			}

			.ar_txts {
				width: 80px;
			}

			input {
				width: 150px;
				height: 24px;
				border: 1px solid rgba(228, 228, 228, 1) !important;
			}

			textarea {
				width: 268px;
				height: 48px;
				border: 1px solid rgba(228, 228, 228, 1) !important;
			}

			p {
				width: 174px;
				height: 52px;
			}

			button {
				width: 174px;
				height: 52px;
				font-size: 22px;
			}

			.w60 input {
				width: 60px;
				margin-right: 20px;
			}
		}
	}

	.titleList {
		height: 66px;
		background: rgba(246, 246, 246, 1);
		display: flex;

		>div {
			width: 20%;
			text-align: center;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(0, 0, 0, 1);
			line-height: 66px;
		}
	}

	.userList {
		height: 92px;
		display: flex;

		>div {
			width: 20%;
			text-align: center;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(0, 0, 0, 1);
			line-height: 92px;

			span {
				display: inline-block;
				text-align: center;
				width: 30%;
				cursor: pointer;
			}

			span:nth-of-type(1) {
				color: #4eeec8;
			}

			span:nth-of-type(2) {
				color: #ee4e4e;
			}
		}
	}

	.box1 {
		width: 1132px;
		margin-left: 34px;
		margin-top: 20px;
		border: 1px solid #e6e6e6;
	}

	.nav {
		height: 68px;
		border-bottom: 1px solid #e6e6e6;
		font-size: 18px;
		padding-left: 20px;
		display: flex;

		>div {
			line-height: 68px;
			padding-left: 16px;
			padding-right: 16px;
			position: relative;
			cursor: Pointer;
		}
	}

	.archives {
		min-height: 690px;
	}

	.navActive {
		color: #4eeec8;
	}

	// 编辑档案
	.editRecord {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.6);

		.el-date-editor {
			margin-top: -7px;
		}
	}

	.editRecord_box {
		width: 690px;
		height: 622px;
		overflow: auto;
		background: white;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.ad_title {
			line-height: 40px;
			height: 40px;
			background: rgba(247, 247, 247, 1);
			font-size: 14px;
			padding-left: 12px;
			padding-right: 12px;
			position: relative;

			.none {
				position: absolute;
				cursor: pointer;
				right: 24px;
				top: 14px;
				width: 14px;
				height: 14px;
			}
		}

		.ar_box {
			padding-left: 30px;
			font-size: 14px;
			padding-top: 20px;

			i {
				margin-right: 8px;
			}

			.ar_txt {
				font-size: 14px;
				margin-bottom: 14px;
				line-height: 24px;
				display: flex;
				overflow: hidden;
			}

			.ar_txts {
				width: 80px;
			}

			.iscard {
				input {
					width: 200px;
				}
			}

			input {
				width: 150px;
				height: 24px;
				border: 1px solid rgba(228, 228, 228, 1) !important;
			}

			textarea {
				width: 268px;
				height: 48px;
				border: 1px solid rgba(228, 228, 228, 1) !important;
			}

			p {
				width: 80px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(240, 0, 0, 1);
				border-radius: 8px;
				margin: auto;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(240, 0, 0, 1);
				margin-bottom: 40px;
			}

			.w60 input {
				width: 60px;
				margin-right: 20px;
			}
		}
	}
</style>
